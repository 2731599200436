import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './Input.module.scss';

const Input = ({
    error = false,
    type = "text",
    name,
    placeholder,
    value = "",
    max,
    min = 0,
    style,
    className,
    required = false,
    disabled = false,
    onChange = () => null,
    onFocus = () => null,
    onBlur = () => null,
    onKeyPress = () => null,
    onKeyDown = () => null,
}) => {
    const language = useSelector((state) => state.auth.language);
    const [isActive, setIsActive] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    useEffect(() => {
        if (value === "" || value === null) {
            setIsActive(false)
        }
        else {
            setIsActive(true)
        }
    }, [value])

    return (
        <div className={`${classes.container} ${className}`} style={style}>
            <label className={(isActive || value) && classes.inputFocus} htmlFor={name} style={language === 'ar' ? { right: "3%", paddingRight: "10px" } : null}>
                {placeholder} {required && <span style={{ color: "var(--errorColor)", textDecoration: "none" }}> *</span>}
            </label>
            <input
                type={type}
                id={name}
                name={name}
                max={max}
                min={min}
                maxLength={max}
                value={value}
                disabled={disabled}
                readOnly={readOnly}
                onChange={(e) => {
                    if (type === "number" && +e.target.value > max) {
                        onChange({ target: { value: max } })
                    }
                    else if (type === "number" && +e.target.value < min) {
                        onChange({ target: { value: "" } })
                    }
                    else {
                        onChange(e)
                    }
                }}
                onBlur={() => {
                    setIsActive(value || false)
                    if (value === 0) {
                        setIsActive(true)
                    }
                    onBlur()
                }}
                onFocus={() => {
                    setIsActive(true)
                    setReadOnly(false)
                    onFocus()
                }}
                onKeyPress={(e) => onKeyPress(e)}
                onKeyDown={(e) => onKeyDown(e)}
                style={{
                    borderColor: error ? "var(--errorColor)" : "#dddddd",
                }}
            />
        </div>
    )
}

export default Input;