import { configureStore } from "@reduxjs/toolkit";
import { authReducer, authSlice } from "./slices/authSlice";
import { layoutReducer, layoutSlice } from "./slices/layoutSlice";
import { walkinReducer, walkinSlice } from "./slices/walkinSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        layout: layoutReducer,
        walkin: walkinReducer,
    },
})

export const authActions = authSlice.actions;
export const layoutActions = layoutSlice.actions;
export const walkinActions = walkinSlice.actions;