import { CgCloseR } from "react-icons/cg";
import { SlClose } from "react-icons/sl";
import classes from "./Toast.module.scss";
import successPic from "../../assets/images/success-toast.png";
import errorPic from "../../assets/images/error-toast.png";

const Toast = ({ title, body, onClose, success }) => {
    
  return (
    <div className={classes.toast} style={{
        backgroundImage: success ? `url(${successPic})` : `url(${errorPic})`
    }}>
      <div className={classes.subBox}>
        <div className={classes.words}>
          <SlClose size={30} style={{
                color: success ? "var(--successColor)" : "var(--errorColor300)"
          }}/>
          <div>
            <h4 className="p-0 m-0" style={{
                color: success ? "var(--successColor)" : "var(--errorColor300)"
            }}>{title}</h4>
            <p className="p-0 m-0 mt-1">
              {body}
            </p>
          </div>
        </div>
        <div className={classes.close}>
          <CgCloseR onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default Toast;
