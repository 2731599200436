import { gql } from "@apollo/client";

export const SAVE_FIREBASE_TOKEN = gql`
mutation UpdateMyFirebaseToken($firebaseToken: String, $dashboardFirebaseToken: String) {
  updateMyFirebaseToken(firebaseToken: $firebaseToken, dashboardFirebaseToken: $dashboardFirebaseToken) {
    code
    success
    message
  }
}
`

export const SEND_OTP = gql`
mutation SendOTP($countryCode: String!, $phoneNumber: String!) {
  sendOTP(countryCode: $countryCode, phoneNumber: $phoneNumber) {
    code
    message
    success
  }
}
`;

export const CHECK_OTP = gql`
mutation CheckOTP($countryCode: String!, $phoneNumber: String!, $otpPasscode: String!) {
  checkOTP(countryCode: $countryCode, phoneNumber: $phoneNumber, otpPasscode: $otpPasscode) {
    code
    message
    success
  }
}
`;

export const CHANGE_PASSWORD = gql`
mutation ForgotPassword($countryCode: String!, $phoneNumber: String!, $newPassword: String!, $confirmPassword: String!) {
  forgotPassword(countryCode: $countryCode, phoneNumber: $phoneNumber, newPassword: $newPassword, confirmPassword: $confirmPassword) {
    message
    success
  }
}
`;
export const SIGNUP = gql`
mutation EasyRegistration($app: App!, $firebaseToken: String, $input: EasyRegistrationInput!) {
  easyRegistration(app: $app, firebaseToken: $firebaseToken, input: $input) {
    code
    message
    success
    token
    user {
     firstName
      lastName
      _id
      aId
      profilePictureUrl
      countryCode
      phoneNumber
      role
      isVerified
    }
  }
}
`

export const LOGIN = gql`
mutation Login($password: String!, $app: App!, $firebaseToken: String, $login: LoginInput) {
  login(password: $password, app: $app, firebaseToken: $firebaseToken, login: $login) {
    code
    message
    success
    token
    user {
      firstName
      lastName
      _id
      aId
      profilePictureUrl
      countryCode
      phoneNumber
      isVerified
    }
  }
}
`;

export const LOGOUT = gql`
mutation LogOut {
  logOut {
    code
    success
    message
  }
}
`;

export const CREATE_PROVIDER_WITH_MAIN_BRANCH = gql`
mutation CreateProviderWithMainBranch($providerInput: CreateProviderInput!, $branchInput: CreateMainBranchInput!, $creatorPosition: String!, $logo: Upload) {
  createProviderWithMainBranch(providerInput: $providerInput, branchInput: $branchInput, creatorPosition: $creatorPosition, logo: $logo) {
    aId
    _id
    name
  }
}
`;

export const CREATE_FREE_TRIAL_SUBSCRIPTION = gql`
mutation CreateFreeTrialSubscription($providerId: ID!, $packageKey: ID!) {
  createFreeTrialSubscription(providerId: $providerId, packageKey: $packageKey) {
    code
    success
    message
    subscription {
      _id
      isFreeTrial
      packageKey
      startDate
      dueDate
    }
  }
}
`