import { useDispatch } from "react-redux";
import words from "../../assets/words";
import { authActions } from "../../store";
import SelectInput from "../ui/SelectInput";
import classes from "./SelectLanguage.module.scss";

const languages = [
  { value: "en", label: "English" },
  { value: "ar", label: "العربية" },
  { value: "tu", label: "Türkçe" },
  { value: "ru", label: "русский" },
  { value: "fr", label: "française" },
]

const SelectLanguage = ({ language }) => {
  const dispatch = useDispatch();
  const selectedLanguage = languages.find((lang) => lang.value === language);

  const selectLanguageHandler = (e) => {
    dispatch(authActions.saveLanguage({ language: e.value }));
  };

  return (
    <div className={classes.selectContainer}>
      <SelectInput placeholder={words.LANGUAGE[language]} name="language" value={selectedLanguage} options={languages} clearable={false} onChange={(e) => selectLanguageHandler(e)} />
    </div>
  );
};

export default SelectLanguage;
