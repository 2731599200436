import { Children } from "react";
import { useSelector } from "react-redux";
import Select, { components } from 'react-select';
import words from "../../assets/words";
import classes from "./SelectInput.module.scss";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}{props.selectProps.required && <span style={{ color: "var(--errorColor)", textDecoration: "none" }}> *</span>}
      </Placeholder>
      {Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const SelectInput = ({ name, selectStyle, value, options, placeholder, clearable = true, disabled = false, required = false, searchable = true, multiple = false, onChange, onMenuScrollToBottom, isLoading, inputValue, onInputChange, isOptionDisabled }) => {
  const language = useSelector((state) => state.auth.language);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      lineHeight: "2",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: state.isFocused ? "1px solid #86b7fe" : "1px solid hsl(0, 0%, 80%)",
      boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : "none",
      '&:hover': {
        borderColor: state.isFocused ? "hsl(0, 0%, 70%)" : ""
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "auto",
      maxHeight: "42.3px",
      width: "100%",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      gridArea: "inherit",
      top: state.hasValue || state.selectProps.inputValue ? "2%" : "28%",
      right: state.isRtl ? "4%" : "auto",
      left: 0,
      margin: 0,
      lineHeight: 1.2,
      fontSize: (state.hasValue || state.selectProps.inputValue) ? "10px" : "14px",
      fontWeight: "normal",
      color: "grey",
      textTransform: "capitalize",
      transition: "ease-in-out 0.2s",
      cursor: "text",
      paddingLeft: "10px !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: "0.4rem 0 0 0",
      color: "var(--primaryColor700)"
    }),
    input: (provided) => ({
      ...provided,
      paddingBottom: 0,
      color: "var(--primaryColor700)"
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 100000,
    }),
    multiValue: (provided) => ({
      ...provided,
      maxWidth: "10em"
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : state.isDisabled ? "var(--grayColor200)" : "var(--primaryColor700)",
      backgroundColor: state.isSelected ? "var(--primaryColor)" : "white",
      '&:hover': {
        backgroundColor: state.isSelected ? "" : "var(--grayColor100)"
      }
    })
  };

  return (
    <div className={classes.container} style={selectStyle}>
      <Select
        components={{
          ValueContainer: CustomValueContainer
        }}
        placeholder={placeholder}
        required={required}
        className="basic-single"
        classNamePrefix="select"
        styles={customStyles}
        value={value}
        defaultValue={value}
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        isMulti={multiple}
        closeMenuOnSelect={multiple ? false : true}
        isRtl={language === "ar"}
        name={name}
        options={options}
        noOptionsMessage={() => isLoading ? words.LOADING[language] : words.NO_MORE_OPTIONS[language]}
        onChange={(e) => onChange(e)}
        menuPosition='absolute'
        menuPlacement='auto'
        menuShouldScrollIntoView={false}
        menuPortalTarget={document.getElementById('select-menu-root')}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isOptionDisabled={isOptionDisabled}
        // onWheel={onWheel}
        isLoading={isLoading}
        inputValue={inputValue}
        onInputChange={onInputChange}
      />
    </div>
  );
};

export default SelectInput;
