import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../store';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../graphql/auth/Mutations';
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { FiLogOut } from "react-icons/fi";
import { FaRegUser, FaUser } from "react-icons/fa";
import { RiArrowLeftRightLine } from "react-icons/ri";
import words from '../../assets/words';
import classes from './ProfileSettings.module.scss';
import { RxQuestionMarkCircled } from "react-icons/rx";
import { useMemo, useState } from 'react';
import withModal from '../ui/withModal';
import ContactUsForm from '../general/ContactUsForm';
import SuccessMessage from '../ui/SuccessMessage';

const ProfileSettings = () => {
    const { language, generalInfo, user, token, country } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showContactUs, setShowContactUs] = useState(false);
    const [showDone, setShowDone] = useState(false);

    const [logout] = useMutation(LOGOUT, {
        onCompleted: (data) => {
            if (data.logOut.success) {
                dispatch(authActions.logout());
                const redirectURL = generalInfo.ayamedicaSSOURL;
                // const redirectURL = "http://localhost:3002";
                window.location.href = redirectURL;
            }
        },
    });


    const myProfileHandler = () => {
        navigate('/profile');
    }

    const switchAccountHandler = () => {
        sessionStorage.removeItem("organization")
        const redirectURL = `${generalInfo.ayamedicaSSOURL}welcome?country=${encodeURIComponent(country)}`;
        // const redirectURL = `http://localhost:3002/welcome?country=${encodeURIComponent(country)}?${encodeURIComponent(language)}`;
        window.location.href = redirectURL;
    }

    const logoutHandler = () => {
        logout();
    };


    const handleContactUs = () => {
        setShowContactUs(true);
    }

    const ContactUsModal = useMemo(() => withModal(ContactUsForm), []);
    const ContactUsSuccess = useMemo(() => withModal(SuccessMessage), []);

    return (
        <div className={classes.container}>

            <ContactUsModal show={showContactUs}
                onHide={() => setShowContactUs(false)}
                title={words.CONTACT_US[language]}
                dialogClassName={classes.modal}
                onDone={() =>{
                    setShowContactUs(false)
                    setShowDone(true)
                }}
            />

            <ContactUsSuccess show={showDone}
                onHide={() => setShowDone(false)}
                title={words.CONTACT_US[language]}
                message={words.TICKET_CREATED_SUCCESSFULLY[language]}
                onDone={() => setShowDone(false)}
            />

            <NavDropdown
                className={classes.dropdown}
                title={
                    <div>
                        {generalInfo?.profilePictureBaseURL && <img
                            src={generalInfo?.profilePictureBaseURL + user?.profilePictureUrl}
                            alt="User"
                            className="avatar"
                            style={{ width: "70px" }}
                        />}
                    </div>
                }>

                <Dropdown.Item onClick={myProfileHandler} style={language === 'ar' ? { textAlign: "right" } : undefined}>
                    <FaRegUser size={18} /> {words.MY_PROFILE[language]}
                </Dropdown.Item>
                <Dropdown.Item onClick={switchAccountHandler} style={language === 'ar' ? { textAlign: "right" } : undefined}>
                    <RiArrowLeftRightLine size={18} /> {words.SWITCH_ACCOUNT[language]}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleContactUs} style={language === 'ar' ? { textAlign: "right" } : undefined}>
                    <RxQuestionMarkCircled size={18} /> {words.CONTACT_US[language]}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logoutHandler}>
                    <FiLogOut size={18} /> {words.LOG_OUT[language]}
                </Dropdown.Item>
            </NavDropdown>

        </div>
    );
};

export default ProfileSettings;