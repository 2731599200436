import { useSelector } from "react-redux";
import Button from "../../components/ui/Button";
import Textarea from "../../components/ui/Textarea";
import words from "../../assets/words";
import classes from "./ContactUsForm.module.scss";
import contacts from "../../assets/images/contacts.png";
import contacts2 from "../../assets/images/contacts2.png";
import whats from "../../assets/images/whats.png";
import telegram from "../../assets/images/telegram.png";
import { IoIosArrowDown } from "react-icons/io";
import Input from "../../components/ui/Input";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { selectInput } from "../../util/formatWords";
import { CREATE_TICKET } from "../../graphql/main/Mutations";
import SelectInput from "../ui/SelectInput";
import { READ_TICKET_REASONS } from "../../graphql/main/Queries";

const ContactUsForm = ({ onDone }) => {
    const { language, country } = useSelector(state => state.auth)
    const [reasons, setReasons] = useState([])
    const [data, setData] = useState({
        reason: "",
        message: ""
    })
    const [error, setError] = useState("")

    const { loading: reasonsLoading } = useQuery(READ_TICKET_REASONS, {
        context: { uri: "https://ayamedica-cs-api-staging-b9027fdec1bd.herokuapp.com/graphql" },
        onCompleted: data => {
            setReasons(selectInput(data.readTicketReason.data, "key", "value"))
        }
    })

    const [createTicket, { loading }] = useMutation(CREATE_TICKET, {
        onCompleted: (data) => {
            if (data.createUserTicket.success) {
                setData({
                    reason: "",
                    message: ""
                })
                onDone()
                setError("")
            } else {
                setError(data.createUserTicket.message)
            }
        },
        onError: (err) => {
            setError(err.message)
        }
    })

    const handleCreateTicket = () => {
        createTicket({
            variables: {
                country,
                source: "OUR_PRODUCT",
                platform: "WORKLIST",
                ticketReason: data.reason?.value,
                comment: data.message
            }
        })
    }


    return (
        <div className={classes.form}>
            <p className={classes.title}>{words.CONTACT_US_DESC[language]}</p>
            <div className={classes.cs}>
                {
                    error && <p className="text-danger text-center">{error}</p>
                }
                <p className={classes.customer}>{words.CUSTOMER_SUPPORT[language]}</p>

                <div className={classes.contacts}>
                    <div className={classes.phone}>
                        <img src={contacts} alt='phone' />
                        <div>
                            <p>{words.CONTACT_NUMBER[language]}</p>
                            <p>01091808186</p>
                        </div>
                    </div>
                    <div className={classes.social}>
                        <img src={telegram} alt="telegram" onClick={() => {
                            window.open("https://t.me/+201091808186", "_blank")
                        }} />
                        <img src={whats} alt="whats" onClick={() => {
                            window.open("https://web.whatsapp.com/send?phone=+201091808186", "_blank")
                        }} />
                    </div>
                </div>
{/* 
                <div className={classes.contacts}>
                    <div className={classes.phone}>
                        <img src={contacts2} alt='phone' />
                        <div>
                            <p>{words.CONTACT_EMAIL[language]}</p>
                            <p>Cs@ayamedica.com</p>
                        </div>
                    </div>
                    <div className={classes.social}>
                        <IoIosArrowDown />
                    </div>
                </div> */}

                <div className={classes.inputs}>
                    <SelectInput
                        value={data.reason}
                        placeholder={words.SUBJECT[language]}
                        selectStyle={{ margin: 0 }}
                        required
                        options={reasons}
                        isLoading={reasonsLoading}
                        onChange={(e) => setData({ ...data, reason: e })}
                    />
                    <Textarea
                        placeholder={words.ENTER_YOUR_MESSAGE[language]}
                        value={data.message}
                        areaStyle={{height: 150}} style={{margin: 0, marginBottom: 20}}
                        required
                        onChange={(e) => setData({ ...data, message: e.target.value })}
                    />
                    <Button onClick={handleCreateTicket} disabled={!data.reason || data.message?.length < 20} loading={loading}>{words.SUBMIT[language]}</Button>
                </div>
            </div>
        </div>
    )
}

export default ContactUsForm;