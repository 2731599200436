import { useEffect, useState } from "react";
import classes from "./InternetConection.module.scss";
import words from "../../assets/words";
import { useSelector } from "react-redux";
import Toast from "../ui/Toast";

const InternetConection = () => {
  const language = useSelector((state) => state.auth.language);
  const [isOnline, setIsOnline] = useState(true);
  const [closeToast, setCloseToast] = useState(false);

  const handleOnline = () => {
    setIsOnline(true);
    setCloseToast(false);
  };
  const handleOffline = () => {
    setIsOnline(false);
    setCloseToast(false);
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      { isOnline || closeToast ? null : (
        <div className={classes.container}>
          <Toast
            onClose={() => setCloseToast(true)}
            title={words.CONNECTION_ERROR[language]}
            body={words.CHECK_YOUR_NETWORK[language]}
          />
        </div>
      )}
    </>
  );
};

export default InternetConection;
