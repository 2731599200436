import { gql } from "@apollo/client";


export const READ_ALL_EXCEL_DATA_PAGINATED = gql`
query ReadAllExceldataPaginated($branchId: ID!, $successPage: Int, $rejectPage: Int) {
  readAllExceldataPaginated(branchId: $branchId, successPage: $successPage, rejectPage: $rejectPage) {
    validated {
      list {
        student {
          firstName
          lastName
          countryOfCitizenship
          nationalId
          grade
          class
          photoUrl
          gender
          dateOfBirth
          passportId
          errors {
            firstName
            lastName
            phoneNumber
            email
            nationalId
            class
            dateOfBirth
            gender
            grade
            nationality
            others
            relation
            countryCode
            passportId
          }
        }
        firstGuardian {
          firstName
          lastName
          phoneNumber
          email
          relation
          countryOfCitizenship
          nationalId
          gender
          dateOfBirth
          passportId
          errors {
            firstName
            lastName
            phoneNumber
            email
            nationalId
            class
            dateOfBirth
            gender
            grade
            nationality
            others
            relation
            countryCode
            passportId
          }
          countryCode
        }
        secondGuardian {
          firstName
          lastName
          phoneNumber
          email
          relation
          countryOfCitizenship
          nationalId
          gender
          dateOfBirth
          passportId
          errors {
            firstName
            lastName
            phoneNumber
            email
            nationalId
            class
            dateOfBirth
            gender
            grade
            nationality
            others
            relation
            countryCode
            passportId
          }
          countryCode
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
    rejected {
      list {
        student {
          firstName
          lastName
          countryOfCitizenship
          nationalId
          grade
          class
          photoUrl
          gender
          dateOfBirth
          passportId
          errors {
            firstName
            lastName
            phoneNumber
            email
            nationalId    
            class
            grade
            gender
            relation
            others
            nationality
            dateOfBirth
            countryCode
            passportId
          }
        }
        firstGuardian {
          firstName
          lastName
          phoneNumber
          email
          relation
          countryOfCitizenship
          nationalId
          gender
          dateOfBirth
          passportId
          errors {
            firstName
            lastName
            phoneNumber
            email
            nationalId
            class
            gender
            grade
            relation
            dateOfBirth
            nationality
            others
            countryCode
            passportId
          }
          countryCode
        }
        secondGuardian {
          firstName
          lastName
          phoneNumber
          email
          relation
          countryOfCitizenship
          nationalId
          gender
          dateOfBirth
          passportId
          errors {
            firstName
            lastName
            phoneNumber
            email
            nationalId
            class
            gender
            grade
            relation
            dateOfBirth
            nationality
            others
            countryCode
            passportId
          }
          countryCode
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`

export const READ_ALL_EXCEL_DATA = gql`
query ReadAllExceldata($branchId: ID!) {
  readAllExceldata(branchId: $branchId) {
    validated {
      student {
        firstName
        lastName
        countryOfCitizenship
        nationalId
        grade
        class
        photoUrl
        gender
        dateOfBirth
        passportId
        errors {
          firstName
          lastName
          phoneNumber
          email
          nationalId
          class
          dateOfBirth
          gender
          grade
          nationality
          others
          relation
          countryCode
          passportId
        }
      }
      firstGuardian {
        firstName
        lastName
        phoneNumber
        email
        relation
        countryOfCitizenship
        nationalId
        gender
        dateOfBirth
        passportId
        errors {
          firstName
          lastName
          phoneNumber
          email
          nationalId
          class
          dateOfBirth
          gender
          grade
          nationality
          others
          relation
          countryCode
          passportId
        }
        countryCode
      }
      secondGuardian {
        firstName
        lastName
        phoneNumber
        email
        relation
        countryOfCitizenship
        nationalId
        gender
        dateOfBirth
        passportId
        errors {
          firstName
          lastName
          phoneNumber
          email
          nationalId
          class
          dateOfBirth
          gender
          grade
          nationality
          others
          relation
          countryCode
          passportId
        }
        countryCode
      }
    }
    rejected {
      student {
        firstName
        lastName
        countryOfCitizenship
        nationalId
        grade
        class
        photoUrl
        gender
        dateOfBirth
        passportId
        errors {
          firstName
          lastName
          phoneNumber
          email
          nationalId
          class
          dateOfBirth
          gender
          grade
          nationality
          others
          relation
          countryCode
          passportId
        }
      }
      firstGuardian {
        firstName
        lastName
        countryCode
        phoneNumber
        email
        relation
        countryOfCitizenship
        nationalId
        gender
        dateOfBirth
        passportId
        errors {
          firstName
          lastName
          phoneNumber
          email
          nationalId
          class
          dateOfBirth
          gender
          grade
          nationality
          others
          relation
          countryCode
          passportId
        }
      }
      secondGuardian {
        firstName
        lastName
        phoneNumber
        email
        relation
        countryOfCitizenship
        nationalId
        gender
        dateOfBirth
        passportId
        errors {
          firstName
          lastName
          phoneNumber
          email
          nationalId
          class
          dateOfBirth
          gender
          grade
          nationality
          others
          relation
          countryCode
          passportId
        }
        countryCode
      }
    }
  }
}
`

export const READ_MY_PROVIDER_BRANCHES = gql`
query ReadMyProviderBranches($providerId: ID!, $page: Int) {
  readMyProviderBranches(providerId: $providerId, page: $page) {
    list {
      aId
      name
      _id
      logoUrl
      address {
        governorate {
          key
          value
        }
        city {
          key
          value
        }
      }
      providerId {
        logoUrl
      }
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }
}
`;

export const GET_GRADES = gql`
query Grades ($branchId: ID) {
  readSchoolGrades(branchId: $branchId) {
    code
    success
    message
    data {
       _id
    groupKey
    groupValue
    isAssigned
    title
    }
  }
}
`;

export const GET_BRANCH_DETAILS = gql`
query ReadBranchDetails($branchId: ID!) {
  readBranchDetails(branchId: $branchId) {
    _id
    providerId {
      name
      logoUrl
    }
    aId
    name
    email
    countryCode
    phoneNumber
    logoUrl
    address {
      governorate {
        key
        value
      }
      city {
        key
        value
      }
      area
      street
    }
    groups {
      _id
      groupKey
      groupValue
      isAssigned
      subGroups {
        _id
        title
        name
        maxUsers
      }
    }
  }
}
`

//after creating a branch and on adding classes
export const GET_BRANCH_GRADES = gql`
query ReadBranchDetails($branchId: ID!) {
  readBranchDetails(branchId: $branchId) {
    _id
    groups {
      _id
      name
      subGroups {
        title
        name
        maxUsers
      }
    }
  }
}
`

export const GET_BRANCH_GROUPS_SUPGROUPS = gql`
query ReadSubGroupsDetails($input: ReadSubGroupsDetailsInput) {
  readSubGroupsDetails(input: $input) {
    list {
      group {
        key
        value
      }
      subGroup {
        id
        value
      }
      maxUsers
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }
}
`

export const GET_SUBGROUPS_IN_GROUP = gql`
query ReadSubGroupsInAGroup($branchId: ID!, $groupKey: String!) {
  readSubGroupsInAGroup(branchId: $branchId, groupKey: $groupKey) {
    code
    success
    message
    data {
        _id
    title
    name
    maxUsers
    }
  }
}
`

export const READ_MY_PROVIDER_MEMBERS = gql`
query ReadMyProviderMembers($providerId: ID, $branchId: ID, $page: Int) {
  readMyProviderMembers(providerId: $providerId, branchId: $branchId, page: $page) {
    list {
      branch {
        id
        value
      }
      _id
      aId
      firstName
      lastName
      dateOfBirth
      countryCode
      phoneNumber
      email
      gender {
        key
        value
      }
      address {
        country {
          key
          value
        }
        governorate {
          key
          value
        }
        city {
          key
          value
        }
        area
        street
      }
      profilePictureUrl
      position {
        id
        value
      }
      role {
        id
        value
      }
      category
      speciality
      requestStatus
      messageId
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }
}
`

export const READ_USER_BY_ID = gql`
query ReadUserById($id: ID) {
  readUserById(_id: $id) {
    code
    success
    message
    data {
        _id
    aId
    firstName
    lastName
    profilePictureUrl
    bloodType {
      id
      value
    }
    weight
    height
    countryCode
    phoneNumber
    address {
      country {
        key
        value
      }
      governorate {
        key
        value
      }
      city {
        key
        value
      }
      area
      street
      postalCode
    }
    email
    legalGuardians {
      countryCode
      phoneNumber
      firstName
      lastName
    }
    dateOfBirth
    insuranceCompany
    insuranceId
    goToHospital
    passportId
    branchesData {
      groupKey {
        key
        value
      }
      subGroupId {
        id
        value
      }
    }
    nationalId
    countryOfCitizenship {
      key
      value
      id
    }
    gender {
      key
      value
    }
    policyNumber
    membershipNumber
    insuranceId
    whiteListId {
      drugs {
        _id
        drugName
        ID
      }
      isMessageSeen
    }
    }
  }
}
`

export const READ_USER_BY_AID = gql`
query ReadUserByAId($aId: String!) {
  readUserByAId(aId: $aId) {
    code
    success
    message
    data {
        _id
    aId
    firstName
    lastName
    email
    role
    profilePictureUrl
    }
  }
}
`

export const READ_STUDENT_BY_AID = gql`
query ReadStudentByAId($branchId: ID!, $aId: String!) {
  readStudentByAId(branchId: $branchId, aId: $aId) {
      _id
    aId
    firstName
    lastName
    email
    role
    profilePictureUrl
    branchesData {
      groupKey {
        key
        value
      }
      subGroupId {
        key
        value
      }
    }
  }
}
`

export const READ_PERMISSIONS = gql`
query ReadBranchUserRoles {
  readBranchUserRoles {
    id
    key
    value
  }
}
`
export const READ_NEW_MEMBER_BASIC_QUERIES = gql`
query ReadNewMemberBasicQueries {
  readBranchUserRoles {
    id
    key
    value
  }
  readBranchUserPositions {
    id
    key
    value
  }
}
`


export const READ_NEW_ORGANIZATION_BASIC_QUERIES = gql`
query ReadNewOrganizationBasicQueries{
  readEducationTypes {
    key
    value
  }
  readBranchUserPositions {
    id
    key
    value
  }
   readBranchTypeLookUps {
    key
    value
  }
  readCountries {
    data {
      key
    value
    }
    code
    message
    success
  }
}
`

export const READ_POSITIONS = gql`
query ReadBranchUserPositions {
  readBranchUserPositions {
    id
    key
    value
  }
}
`

export const READ_BLOOD_TYPES = gql`
query ReadBloodTypes {
  readBloodTypes {
    id
    key
    value
  }
}
`

export const READ_PROFILE_BASIC_QUERIES = gql`
query ReadProfileBasicQueries {
  readBloodTypes {
    data {
    id
    key
    value
    }
    code
    message
    success
  }
  readCountries {
      data {
      key
    value
    }
    code
    message
    success
 
  }
  readGovernorates {
    data {
      key
    value
    }
    code
    message
    success
  }
}
`

export const READ_FILTER_APPOINTMENTS_BASIC_QUERIES = gql`
query ReadFilterAppointmentsBasicQueries($app: App!, $branchId: ID!, $isSelectedDisesase: Boolean) {
  readAppointmentTypes {
    code
    success
    message
    data {
       id
    key
    value
    }
  }
  readDiseasesLookUps(app: $app, branchId: $branchId, isSelectedDisesase: $isSelectedDisesase) {
    code
    message
    success
    data {
    id
    key
    value
    }
  }
  readSchoolGrades(branchId: $branchId) {
    code
    message
    success
    data {
      _id
      groupKey
      groupValue
      isAssigned
      title
    }
  }
}
`

export const READ_APPOINTMENT_TYPES = gql`
query ReadAppointmentTypes {
  readAppointmentTypes {
    id
    key
    value
  }
}
`

export const READ_APPOINTMENTS_STATUS = gql`
query ReadAppointmentStatuses {
  readAppointmentStatuses {
    id
    key
    value
    color
  }
}
`

export const READ_ALL_APPOINTMENTS = gql`
query ReadAppointments($branchId: ID!, $page: Int!, $appointmentStatusKey: String!, $diseaseNames: [String], $diseasesTypes: [String], $groupKeys: [String], $subGroups: [String], $studentIds: [ID], $startDate: Date, $endDate: Date, $appointmentType: String, $vaccineType: [String]) {
  readAppointments(branchId: $branchId, page: $page, appointmentStatusKey: $appointmentStatusKey, diseaseNames: $diseaseNames, diseasesTypes: $diseasesTypes, groupKeys: $groupKeys, subGroups: $subGroups, studentIds: $studentIds, startDate: $startDate, endDate: $endDate, appointmentType: $appointmentType, vaccineType: $vaccineType) {
    code
    success
    message
    data {
       list {
      _id
      canceledBy
      patients {
        userAId
        userId
        userName
        profilePictureUrl
      }
      appointmentType {
        task {
          key
          value
        }
        vaccineType {
          key
          value
          id
        }
        medicalRecordId
      }
      appointmentStatus {
        key
        value
      }
      date
      checkOutDate
      doctor {
        _id
        firstName
        lastName
      }
      time {
        key
        value
      }
      groups {
        key
        value
      }
      subGroups {
        id
        value
      }
      includesOnePatient
      theOnePatientId {
        _id
        aId
        firstName
        lastName
        profilePictureUrl
      }
      diseaseTypeCode {
        id
        typeName
        diseaseKey {
          key
          value
        }
      }
      disease {
        key
        value
        id
      }
    }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
}
`

export const CHECK_IF_APPOINMENT_EXISTS= gql`
query CheckIfAppointmentExists($doctorId: ID!, $time: String!, $date: Date!, $patientsIds: [ID]!) {
  checkIfAppointmentExists(doctorId: $doctorId, time: $time, date: $date, patientsIds: $patientsIds) {
    code
    success
    message
  }
}
`

export const READ_APPOINTMENT_DETAILS = gql`
query ReadAppointmentPatients($appointmentId: ID!, $page: Int!, $status: Boolean, $searchInput: String) {
  readAppointmentPatients(appointmentId: $appointmentId, page: $page, status: $status, searchInput: $searchInput) {
    code
    success
    message
    data {
      pagination {
        totalDocuments
        viewLimit
      }
      list {
        userId
        userName
        userAId
        grade {
          key
          value
        }
        profilePictureUrl
        status
        note
        checkDate
        hygieneData {
          hygieneName {
            key
            value
          }
          status
          reason
        }
      }
      appointmentData {
        grade {
          key
          value
        }
        totalPatientsCount
        unCheckedPatientsCount
        checkedPatientsCount
        includesOnePatient
        patientsSucceeded
        patientsFailed
        allPatientsChecked
        createdAt
        patients {
          _id
          firstName
          lastName
          aId
          profilePictureUrl
        }
        appointmentType {
          key
          value
        }
        appointmentStatus {
          key
          value
        }
        cancelReason
      }
    }
  }
  readHygienes {
    code
    success
    message
    data {
        _id
      hygieneName
      data {
        id
        key
        value
    }
    }
  }
}
`

export const READ_WALK_IN_PROCESS_QUERIES = gql`
query walkinProcess($medicalRecordId: ID, $patientId: ID!) {
  readMedicalRecordDetails(medicalRecordId: $medicalRecordId) {
  code
  success
  message
  data {
     _id
      branchName
      specialty
      complaints
      assessmentNotes
      plan {
        _id
        activeIngredient
        administrationForm
        days
        drugName
        endDate
        hours
        note
        relationToFood
        startDate
      }
      currentMedicationsThen {
        _id
        activeIngredient
        administrationForm
        days
        drugName
        endDate
        hours
        note
        relationToFood
        startDate
      }
    schoolExamination
    schoolAssessment {
      suspectedDisease {
        key
        value
      }
      recommendation {
        key
        value
      }
    }
    sickLeaveData {
      sickLeaveStartDate
      sickLeaveEndDate
      sickLeaveDays
    }
    app
  }
}
  
readCurrentMedication(patientId: $patientId) {
      code
      success
      message
      data {
        
   _id
      activeIngredient
      administrationForm
      days
      drugName
      endDate
      hours
      medicationKey
      note
      relationToFood
      startDate     }
    }

  readRelationsToFood {
    code
    success
    message
    data {
       color
      id
      key
      logo
      value
    }
  }
  readAdminstrationForms {
  code
  success
  message
  data {
      color
      id
      key
      logo
      value 
  }
}
  }
`

export const READ_DISEASES = gql`
query ReadDiseasesLookUps($app: App!) {
  readDiseasesLookUps(app: $app,) {
    code
    message
    success
    data {
      id
      key
      value
    }
  }
}
`

export const READ_DISEASE_TYPES = gql`
query ReadDiseaseTypesLookUps($diseaseKey: ID!) {
  readDiseaseTypesLookUps(diseaseKey: $diseaseKey) {
    id
    typeName
  }
}
`

export const READ_BRANCH_GROUPS = gql`
query ReadMyBranchGroups($branchId: ID!) {
  readMyBranchGroups(branchId: $branchId) {
    groupKey
    groupValue
  }
}
`

export const READ_TIME_SLOTS = gql`
query ReadTime {
  readTime {
    key
    value
  }
}
`

export const READ_FILTER_STUDENTS_BASIC_QUERIES = gql`
query ReadFilterStudentsBasicQueries($app: App!, $branchId: ID!, $isSelectedDisesase: Boolean) {
  readDiseasesLookUps(app: $app, branchId: $branchId, isSelectedDisesase: $isSelectedDisesase) {
    code
    message
    success
    data {
      id
      key
      value
    }
  }
  readSchoolGrades(branchId: $branchId) {
    code
    message
    success
    data {
      _id
      groupKey
      groupValue
      isAssigned
      title
    }
  }
}
`

export const READ_VACCINE_APPOINTMENT_BASIC_QUERIES = gql`
query ReadVaccineAppointmentBasicQueries($branchId: ID!, $isSelectedDisesase: Boolean) {
    readTime {
    key
    value
  }
  readVaccinationTypes(branchId: $branchId, isSelectedDisesase: $isSelectedDisesase) {
    code
    success
    message
    data {
       id
    key
    value
    color
    logo
    }
  }

  readMyBranchUsers(branchId: $branchId) {
    code
    message
    success
    data {
    firstName
    lastName
    aId
    _id
    }
  }
  readSchoolGrades(branchId: $branchId) {
    code
    message
    success
    data {
      _id
      groupKey
      groupValue
      isAssigned
      title
    }
  }
}
`

export const READ_APPOINTMENT_BASIC_QUERIES = gql`
query ReadAppointmentBasicQueries($app: App!, $branchId: ID!, $isSelectedDisesase: Boolean, $appointmentType: String) {
    readTime {
    key
    value
  }
  readDiseasesLookUps(app: $app, branchId: $branchId, isSelectedDisesase: $isSelectedDisesase, appointmentType: $appointmentType) {
    code
    message
    success
    data {
      id
      key
      value  
    }
  }  
  
  readMyBranchUsers(branchId: $branchId) {
    code
    message
    success
    data {
    firstName
    lastName
    aId
    _id
    }
  }
  readSchoolGrades(branchId: $branchId) {
    code
    message
    success
    data {
      _id
      groupKey
      groupValue
      isAssigned
      title
    }
  }
}
`

export const READ_STUDENTS_IN_SUBGROUPS = gql`
query ReadSubGroupUsers($branchId: ID!, $groupKey: String!, $subGroupId: ID!) {
  readSubGroupUsers(branchId: $branchId, groupKey: $groupKey, subGroupId: $subGroupId) {
    code
    success
    message
    data {
       _id
    aId
    firstName
    lastName
    profilePictureUrl
    }
  }
}
`

export const READ_BRANCH_DOCTORS = gql`
query ReadMyBranchUsers($branchId: ID!) {
  readMyBranchUsers(branchId: $branchId) {
    code
    message
    success
    data {
    firstName
    lastName
    aId
    _id
    }
  }
}
`

export const READ_ALL_PROVIDER_STUDENTS = gql`
query SearchForStudent($branchId: ID!, $searchInput: String, $page: Int, $diseaseNames: [String], $diseasesTypes: [ID], $gradeKeys: [String], $subGroupsIds: [ID], $minMedicalRecord: Int, $maxMedicalRecord: Int, $limit: Int, $lastAppointment: [String]) {
  searchForStudent(branchId: $branchId, searchInput: $searchInput, page: $page, diseaseNames: $diseaseNames, diseasesTypes: $diseasesTypes, gradeKeys: $gradeKeys, subGroupsIds: $subGroupsIds, minMedicalRecord: $minMedicalRecord, maxMedicalRecord: $maxMedicalRecord, limit: $limit, lastAppointment: $lastAppointment) {
    code
    success
    message
    data {
          list {
      _id
      aId
      firstName
      lastName
      gender {
        key
        value
      }
      dateOfBirth
      lastAppointment {
        _id
        task
      }
      countryOfCitizenship {
        key
        value
      }
      nationalId
      passportId
      legalGuardians {
        countryOfCitizenship {
          key
          value
        }
        dateOfBirth
        email
        firstName
        gender {
          key
          value
        }
        lastName
        nationalId
        passportId
        phoneNumber
        countryCode
        relationshipToUser {
          key
          value
        }
        status
      }
            branchesData {
              branchId
              branchName
              groupKey {
                key
                value
              }
              subGroupId {
                id
                value
              }
            }
            countMedicalRecords
            countryCode
            email
            isVerified
            phoneNumber
            profilePictureUrl
            medicalHistory
          }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
}`

export const READ_COUNT_OF_STUDENTS_HAS_DISEASE = gql`
query CountNumberOfStudentsHasDisease($branchId: ID!, $diseaseNames: [String], $diseasesTypes: [String], $gradeKeys: [String], $subGroupsIds: [ID], $minMedicalRecored: Int, $maxMedicalRecored: Int) {
  countNumberOfStudentsHasDisease(branchId: $branchId, diseaseNames: $diseaseNames, diseasesTypes: $diseasesTypes, gradeKeys: $gradeKeys, subGroupsIds: $subGroupsIds, minMedicalRecored: $minMedicalRecored, maxMedicalRecored: $maxMedicalRecored) {
    code
    success
    message
    data
  }
}
`
export const READ_COUNT_OF_APPOINMENTS = gql`
query CountReadAppointment($branchId: ID!, $appointmentStatusKey: String!, $appointmentType: String, $vaccineType: [String], $startDate: Date, $endDate: Date, $groupKeys: [String], $subGroups: [String], $diseaseNames: [String], $diseasesTypes: [String], $studentIds: [ID]) {
  countReadAppointment(branchId: $branchId, appointmentStatusKey: $appointmentStatusKey, appointmentType: $appointmentType, vaccineType: $vaccineType, startDate: $startDate, endDate: $endDate, groupKeys: $groupKeys, subGroups: $subGroups, diseaseNames: $diseaseNames, diseasesTypes: $diseasesTypes, studentIds: $studentIds) {
    code
    success
    message
    data
    message
    success
  }
}
`

export const READ_COUNT_OF_MESSAGES = gql`
query ReadCountBranchSentMessages($branchId: ID!, $searchInput: String, $subGroups: [ID], $groupKeys: [String], $doctorId: [ID], $studentIds: [ID], $dateFrom: Date, $dateTo: Date) {
  readCountBranchSentMessages(branchId: $branchId, searchInput: $searchInput, subGroups: $subGroups, groupKeys: $groupKeys, doctorId: $doctorId, studentIds: $studentIds, dateFrom: $dateFrom, dateTo: $dateTo) {
    code
    success
    message
    data
  }
}`

export const READ_COUNT_RECIEVED_RECORDS = gql`
query ReadCountBranchReceviedMessages($branchId: ID!, $groupKeys: [String], $subGroups: [ID], $speciality: [String], $dateFrom: Date, $dateTo: Date, $studentIds: [ID]) {
  readCountBranchReceviedMessages(branchId: $branchId, groupKeys: $groupKeys, subGroups: $subGroups, speciality: $speciality, dateFrom: $dateFrom, dateTo: $dateTo, studentIds: $studentIds) {
    code
    success
    message
    data
  }
}`

export const READ_COUNT_VACCINE_MESSAGES = gql`
query ReadCountVaccineMessages($branchId: ID!, $vaccineType: [String], $groupKeys: [String], $subGroups: [ID], $studentIds: [ID], $dateFrom: Date, $dateTo: Date) {
  readCountVaccineMessages(branchId: $branchId, vaccineType: $vaccineType, groupKeys: $groupKeys, subGroups: $subGroups, studentIds: $studentIds, dateFrom: $dateFrom, dateTo: $dateTo) {
    code
    success
    message
    data
  }
}`

export const COUNT_MAX_MEDICAL_RECORDS = gql`
query CountMaxMedicalRecordForStudents($branchId: ID!) {
  countMaxMedicalRecordForStudents(branchId: $branchId) {
    code
    message
    success
    data
  }
}`

export const READ_ALL_STUDENTS = gql`
query ReadSubGroupsDetails($input: ReadSubGroupsDetailsInput) {
  readSubGroupsDetails(input: $input) {
    list {
      group {
        id
        key
        value
      }
      subGroup {
        id
        key
        value
      }
      maxUsers
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }
}
`

export const READ_GENDERS = gql`
query ReadGenders {
  readGenders {
    id
    key
    value
  }
}
`

export const READ_SIGNUP_BASIC_QUERIES = gql`
query ReadSignupBasicQueries {
   readCountries {
    data {
      key
    value
    }
    code
    message
    success
  }
  readGenders {
    id
    key
    value
  }
}
`

export const READ_RELATIONS = gql`
query ReadRelationshipsToUser {
  readRelationshipsToUser {
    key
    value
  }
}
`

export const READ_STUDENT_BASIC_QUERIES = gql`
query ReadStudentBasicQueries($branchId: ID!) {
  readCountries {
    data {
      key
    value
    }
    code
    message
    success
  }
  readGenders {
    id
    key
    value
  }
  readRelationshipsToUser {
    key
    value
  }
  readSchoolGrades(branchId: $branchId) {
    code
    message
    success
    data {
      _id
      groupKey
      groupValue
      isAssigned
      title
    }
  }
}
`

export const READ_VACCINATION_TYPES = gql`
query ReadVaccinationTypes($branchId: ID, $isSelectedDisesase: Boolean) {
  readVaccinationTypes(branchId: $branchId, isSelectedDisesase: $isSelectedDisesase) {
    code
    success
    message
    data {
       id
    key
    value
    color
    logo
    }
  }
}
`

export const READ_PROBLEMS_LIST = gql`
query ReadProblemListLookUps {
  readProblemListLookUps {
    code
    success
    message
    data {
         key
    value
    }
  }
}
`

export const READ_PROBLEM_DETAILS_LIST = gql`
query ReadProblemListDetailsLookUps($problemKey: String!) {
  readProblemListDetailsLookUps(problemKey: $problemKey) {
    code
    success
    message
    data {
          key
    value
    }
  }
}
`

export const READ_MEDICAL_RECORDS_COMPLAINS = gql`
query ReadMedicalRecordComplaints($specialty: String!, $complaint: String, $page: Int) {
  readMedicalRecordComplaints(specialty: $specialty, complaint: $complaint, page: $page) {
    code
    success
    message
    data {
        list {
      id
      key
      value
    }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
}
`

export const READ_DISEASE_TYPES_LOOKUP = gql`
query ReadDiseaseTypesLookUps($searchInput: String, $diseaseKey: [String], $page: Int, $branchId: ID, $isSelectedDisesase: Boolean) {
  readDiseaseTypesLookUps(searchInput: $searchInput, diseaseKey: $diseaseKey, page: $page, branchId: $branchId, isSelectedDisesase: $isSelectedDisesase) {
    code
    success
    message
    data {
       list {
      _id
      typeName
      typeNameAlias
      diseaseKey
      code
    }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
}
`

export const READ_ALL_SELECTED_DISEASE_STUDENTS = gql`
query ReadSelectedDiseaseStudents($branchId: ID!, $diseaseKey: String, $diseaseTypeCode: String) {
  readSelectedDiseaseStudents(branchId: $branchId, diseaseKey: $diseaseKey, diseaseTypeCode: $diseaseTypeCode) {
    _id
    aId
    firstName
    lastName
  }
}
`

export const PLANS_QUERIES = gql `
query ReadDrugsList($searchInput: String, $page: Int, $ingredients: [String], $branchId: ID!) {
  readDrugsList(searchInput: $searchInput, page: $page, ingredients: $ingredients, branchId: $branchId) {
    code
    message
    success
    data {
     list {
      _id
      ID
      drugName
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }  
    }

    readBranchWhitList(branchId: $branchId) {
      code
      success
      message
      data {
        branchId
        drugs {
          drugName
          _id
        }
        updatesData {
          updateDate
        }
      }
    }
  }
`

export const READ_DRUGS_LIST = gql`
query ReadDrugsList($searchInput: String, $page: Int, $ingredients: [String]) {
  readDrugsList(searchInput: $searchInput, page: $page, ingredients: $ingredients) {
    code
    message
    success
    data {
     list {
      _id
      ID
      drugName
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }  
    }
  }
`

export const READ_RELATIONS_TO_FOOD = gql`query ReadRelationsToFood {
  readRelationsToFood {
    id
    key
    value
  }
}`

export const READ_ADMINSTRATION_FORMS = gql`query ReadAdminstrationForms {
  readAdminstrationForms {
    id
    key
    value
  }
}`

export const READ_DRUG_INGREDIENTS = gql`
query ReadDrugIngredients($searchInput: String, $page: Int) {
  readDrugIngredients(searchInput: $searchInput, page: $page) {
     code
    success
    message
   data {
      list {
      ingredient
    }
    pagination {
      totalDocuments
      viewLimit
    }
   }
  }
}
`

export const READ_MEDICAL_RECORDS_CATEGORIES = gql`
query Category($isSelected: Boolean, $studentId: ID) {
  readCategoriesAndSpecialties(isSelected: $isSelected, studentId: $studentId) {
    code
    success
    message
    data {
      category {
      value
      key
    }
    }
  }
}
`

export const READ_MEDICAL_RECORDS = gql`
query ReadMedicalRecords($dateFrom: Date, $dateTo: Date, $specialties: [String], $patientIds: [ID]!) {
  readMedicalRecords(dateFrom: $dateFrom, dateTo: $dateTo, specialties: $specialties, patientIds: $patientIds) {
    code
    success
    message
    data {
       _id
    date
    specialty
      createdAt
    }
  }
}
`

export const READ_MEDICAL_RECORD_DETAILS = gql`
query ReadMedicalRecordDetails($medicalRecordId: ID) {
  readMedicalRecordDetails(medicalRecordId: $medicalRecordId) {
    code
    success
    message
    data {
       _id
    branchName
    branchName
    doctorName
    doctor {
      _id
      aId
      firstName
      lastName
    }
    patient {
      _id
      aId
      profilePictureUrl
      firstName
      lastName
      bloodType {
        id
        key
        value
      }
      branchesData {
        subGroupId {
          id
          value
        }
        groupKey {
          key
          value
        }
      }
      weight
      height
      firstName
      lastName
      gender {
        key
        value
      }
      dateOfBirth
      goToHospital
      countryCode
      phoneNumber
      legalGuardians {
        countryCode
        phoneNumber
      }
      insuranceCompany
      insuranceId
    }
    createdAt
    branch {
      providerId {
        logoUrl
        name
      }
      logoUrl
      name
    }
    specialty
    complaints
    assessmentNotes
    plan {
      _id
      activeIngredient
      administrationForm
      days
      drugName
      endDate
      hours
      note
      relationToFood
      startDate
    }
    currentMedicationsThen {
      _id
      activeIngredient
      administrationForm
      days
      drugName
      endDate
      hours
      note
      relationToFood
      startDate
    }
  
    branchAvatar
      recordsPhotoURLs {
        url
        isPdf
      }
      note
      app
      schoolExamination
      sickLeaveData {
        sickLeaveStartDate
        sickLeaveEndDate
        sickLeaveDays
      }
      schoolAssessment {
        suspectedDisease {
          key
          value
        }
        recommendation {
          key
          value
        }
      }
    }
  }
}
`

export const READ_MEDICAL_HISTORY = gql`
query ReadWebMedicalHistoryDetails($datefilter: DateFilter, $immunitiesDatefilter2: DateFilter, $infectiousDiseasesDatefilter2: DateFilter, $otherDiseasesDatefilter2: DateFilter, $surgeriesDatefilter2: DateFilter, $vaccinationsDatefilter2: DateFilter, $patientId: ID!, $chronicDiseasesDatefilter2: DateFilter, $otherDatefilter2: DateFilter, $allergiesDatefilter2: DateFilter) {
  readWebMedicalHistoryDetails(patientId: $patientId) {
    code
    success
    message
    data {
       _id
    nationalId
    passportId
    medications(datefilter: $datefilter) {
      _id
      drugName
      administrationForm
      relationToFood
      note
      hours
      days
      startDate
      endDate
    }
    immunities(datefilter: $immunitiesDatefilter2) {
      _id
      date
      diseaseTypeCode {
        id
        code
        typeName
      }
    }
    chronicDiseases(datefilter: $chronicDiseasesDatefilter2) {
      _id
      diseaseTypeCode {
        id
        typeName
        code
      }
      date
    }
    infectiousDiseases(datefilter: $infectiousDiseasesDatefilter2) {
      _id
      diseaseTypeCode {
        id
        code
        typeName
      }
      date
    }
    otherDiseases(datefilter: $otherDiseasesDatefilter2) {
      _id
      diseaseTypeCode {
        id
        code
        typeName
      }
      date
    }
    surgeries(datefilter: $surgeriesDatefilter2) {
      _id
      diseaseTypeCode {
        id
        typeName
      }
      date
    }
    vaccinations(datefilter: $vaccinationsDatefilter2) {
      _id
      vaccinationType {
        key
        value
      }
      date
    }
    other(datefilter: $otherDatefilter2) {
      _id
      diseaseTypeCode {
        id
        code
        diseaseKey {
          key
          value
        }
        typeName
        aliasTypeName
      }
      date
      description
    }
      allergies(datefilter: $allergiesDatefilter2) {
        _id
        diseaseTypeCode {
          id
          code
          diseaseKey {
            id
            key
            value
            color
            logo
          }
          typeName
          aliasTypeName
        }
        date
        description
      }
    }
  }
}
`

export const READ_PHYSICAL_EXAM_CATEGORIES = gql`query ReadPhysicalExamCategories($specialty: String!) {
  readPhysicalExamCategories(specialty: $specialty) {
    categories {
      key
      slides {
        key
        rows {
          fields {
            data {
              id
              key
              value
            }
            extraDisplay
            key
            max
            min
            muliple
            type
            value
            width
          }
        }
        value
      }
      value
    }
    specialty
  }
}`

export const READ_MY_SENT_MESSAGES = gql`
query ReadMyBranchSentMessages($branchId: ID!, $page: Int!, $searchInput: String, $subGroups: [ID], $groupKeys: [String], $doctorId: [ID], $studentIds: [ID], $dateFrom: Date, $dateTo: Date) {
  readMyBranchSentMessages(branchId: $branchId, page: $page, searchInput: $searchInput, subGroups: $subGroups, groupKeys: $groupKeys, doctorId: $doctorId, studentIds: $studentIds, dateFrom: $dateFrom, dateTo: $dateTo) {
    code
    success
    message
    data {
      list {
      attachments {
        filename
        url
      }
      body
      branchData {
        _id
        name
        providerId
        branchType
        aId
        logoUrl
      }
      createdAt
      groupKey {
        key
        value
      }
      key
      messageId
      student {
        _id
        aId
        firstName
        lastName
        profilePictureUrl
      }
      subGroup {
        id
        value
      }
      subject
    }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
} 
`
export const READ_INBOX_QUERIES = gql`
query ReadInboxBasicQueries($branchId: ID!, $isSelectedDisesase: Boolean, $isSelected: Boolean) {
    readMyBranchUsers(branchId: $branchId) {
      code
      success
      message
      data {
        firstName
      lastName
      aId
      _id
      }
    }
    readSchoolGrades(branchId: $branchId) {
      code
      success
      message
      data {
        _id
      groupKey
      groupValue
      isAssigned
      title
      }
    }
    readVaccinationTypes(branchId: $branchId, isSelectedDisesase: $isSelectedDisesase) {
    code
    success
    message
    data {
       id
    key
    value
    color
    logo
    }
  }
  readCategoriesAndSpecialties(branchId: $branchId, isSelected: $isSelected) {
    code
    success
    message
    data {
         category {
      value
      key
    }
    }
  }
}
`

export const MESSAGE_DETAILS = gql`
query ReadBranchMessageDetails($messageId: ID!) {
  readBranchMessageDetails(messageId: $messageId) {
    code
    success
    message
    data {
      attachments {
      filename
      url
    }
    body
    createdAt
    groupKey {
      key
      value
    }
    key
    messageId
    student {
      _id
      aId
      firstName
      lastName
      profilePictureUrl
      branchesData {
        groupKey {
          key
          value
        }
        subGroupId {
          id
          value
        }
      }
    }
    subGroup {
      id
      value
    }
    subject
    branchData {
      _id
      name
      providerId
      branchType
      aId
      logoUrl
    }
    }
  }
}   
`
export const READ_RECIEVED_RECORDS = gql`
query ReadBranchReceviedMessages($branchId: ID!, $groupKeys: [String], $subGroups: [ID], $speciality: [String], $dateFrom: Date, $dateTo: Date, $studentIds: [ID], $page: Int!) {
  readBranchReceviedMessages(branchId: $branchId, groupKeys: $groupKeys, subGroups: $subGroups, speciality: $speciality, dateFrom: $dateFrom, dateTo: $dateTo, studentIds: $studentIds, page: $page) {
    code
    success
    message
    data {
       list {
      body
      createdAt
      isDeliverd
      isRead
      medicalRecordId
      messageId
      student {
        _id
        aId
        firstName
        lastName
        profilePictureUrl
      }
      subject
    }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
}
`

export const READ_VACCINE_REQUESTS = gql`
query ReadVaccineRequest($branchId: ID!, $page: Int!, $vaccineType: [String], $groupKeys: [String], $subGroups: [ID], $studentIds: [ID], $dateFrom: Date, $dateTo: Date) {
  readVaccineRequest(branchId: $branchId, page: $page, vaccineType: $vaccineType, groupKeys: $groupKeys, subGroups: $subGroups, studentIds: $studentIds, dateFrom: $dateFrom, dateTo: $dateTo) {
    code
    success
    message
    data {
       list {
      groupId
      subject
      pending
      accepted
      declined
      responses
      branchSender
      requestStatus
      vaccineType
      requestStatusSelectedBy
      time {
        key
        value
      }
      student {
        _id
        aId
        firstName
        lastName
        profilePictureUrl
        legalGuardians {
          relationshipToUser {
            value
            key
            id
          }
          firstName
          lastName
          countryOfCitizenship {
            value
            key
            id
          }
          dateOfBirth
          gender {
            value
            key
            id
          }
          nationalId
          passportId
          countryCode
          phoneNumber
          email
          status
          userId
        }
      }
      subGroup {
        id
        value
      }
      groupKey {
        key
        value
      }
      date
    }
    pagination {
      totalDocuments
      viewLimit
    }
    }
  }
}
`

export const READ_VACCINE_REQUEST_DETAILS = gql`
query ReadVaccineDetails($branchId: ID!, $groupId: String!, $page: Int, $status: RequestStatus) {
  readVaccineDetails(branchId: $branchId, groupId: $groupId, page: $page, status: $status) {
    code
    success
    message
    data {
        branchSender
    groupKey {
      key
      value
    }
    pagination {
      totalDocuments
      viewLimit
    }
    subGroup {
      id
      value
    }
    status {
      accepted
      all
      declined
      pending
    }
    list {
      requestStatus
      requestStatusSelectedBy
      student {
        _id
        aId
        firstName
        lastName
        legalGuardians {
          countryCode
          phoneNumber
          status
        }
        profilePictureUrl
      }
    
    }
    vaccineType
    }
  }
}
`

export const READ_SUBGROUPS_FROM_GRADES = gql`
query ReadSubGroupsfromGrades($branchId: ID!, $groupKeys: [String]!) {
  readSubGroupsfromGrades(branchId: $branchId, groupKeys: $groupKeys) {
    code
    success
    message
    data {
      group {
      key
      value
    }
    subGroup {
      id
      value
    }
    }
  }
}
`
export const READ_SUBGROUPUSERS_FROM_SUBGROUPS = gql`
query ReadSubGroupsUsers($branchId: ID!, $subGroupsIds: [ID]!) {
  readSubGroupsUsers(branchId: $branchId, subGroupsIds: $subGroupsIds) {
    code
    success
    message
    data {
          _id
    aId
    firstName
    lastName
    profilePictureUrl
    }
  }
}
`
export const READ_NOTIFICATION_NUMBERS = gql`
query NotifactionBadgeNumber($branchId: ID!) {
  notifactionBadgeNumber(branchId: $branchId) {
    code
    success
    message
    data {
      unReadMedicalRecordLength
      unReadOtherMessagesLength
      vaccineRequestNumber
    }
  }
}
`
export const FILTER_VACCINE_MESSAGES = gql`
query FilterVaccineMessages($page: Int!, $vaccineType: String, $groupKey: String, $classIds: [ID], $studentIds: [ID], $branchId: ID) {
  filterVaccineMessages(page: $page, vaccineType: $vaccineType, groupKey: $groupKey, classIds: $classIds, studentIds: $studentIds, branchId: $branchId) {
    list {
      accepted
      branchSender
      createdAt
      declined
      groupId
      groupKey {
        key
        value
      }
      pending
      requestStatus
      responses
      student {
        _id
        aId
        firstName
        lastName
        profilePictureUrl
      }
      subGroup {
        id
        value
      }
      subject
      vaccineType
    }
    pagination {
      totalDocuments
      viewLimit
    }
  }
}
`

export const READ_SUBSCRIPTION_PLANS = gql`
query ReadSchoolPlans($providerId: ID) {
  readSchoolPlans(providerId: $providerId) {
    _id
    package
    providerId
    perks
    price
  }
}
`

export const READ_BRANCH_WHITELIST = gql`
query ReadBranchWhitList($branchId: ID!) {
  readBranchWhitList(branchId: $branchId) {
    code
    success
    message
    data {
      branchId
      drugs {
        drugName
        _id
      }
      updatesData {
        updateDate
      }
    }
  }
}
`

export const READ_HYGIENES = gql `
query ReadHygienes {
  readHygienes {
    _id
    hygieneName
    data {
      id
      key
      value
    }
  }
}
`

export const READ_UNCKECKED_HYGIENE_COUNT = gql `
query ReadUncheckedHygieneStudentsCount($appointmentId: ID!) {
  readUncheckedHygieneStudentsCount(appointmentId: $appointmentId) {
    code
    success
    message
    data {
      unCheckedStudentsCount
      allChecked
    }
  }
}
`

export const READ_TICKET_REASONS = gql`
query ReadTicketReason {
  readTicketReason {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`

export const READ_OTHERS_MESSAGES = gql`
query ReadBranchOtherMesssages($branchId: ID!, $page: Int!, $limit: Int!) {
  readBranchOtherMesssages(branchId: $branchId, page: $page, limit: $limit) {
    code
    success
    message
    data {
      pagination {
        totalDocuments
        viewLimit
      }
      list {
        messageId
        subject
        body
        medicalRecordId
        createdAt
        student {
          _id
          firstName
          lastName
          aId
          profilePictureUrl
          branchesData {
            groupKey {
              key
              value
            }
            subGroupId {
              id
              key
              value
            }
          }
        }
        isRead
        isDeliverd
        sickLeaveEndDate
        inboxtype
        comment
      }
    }
  }
}
`

export const READ_SUSPECTED_DISEASES = gql `
query ReadSuspectedDiseases {
  readSuspectedDiseases {
    code
    success
    message
    data {
      id
      key
      value
    }
  }
}
`

export const READ_DOCTORS_SPECIALITIES = gql `
query ReadDoctorsSpecialities {
  readDoctorsSpecialities {
    code
    success
    message
    data {
      id
      key
      value
    }
  }
}
`